<template>
  <spinner v-if="loading" centered />
  <div v-else-if="resource" class="app-panel">
    <div id="publishing-detail-container" class="app-panel-body scrollable">
      <nav class="py-3 px-4 mb-2 d-flex align-items-center justify-content-between">
        <div>
          <a href="#" @click.prevent="close" class="fs-18 mr-3 ml-1" style="position: relative; top: 2px">
            <fa-icon name="times" />
          </a>
        </div>
      </nav>
      <main class="flex">
        <div class="mr-auto my-1 ml-6 invisible" />
        <div class="py-0 pl-4 pr-6 w-11/12">
          <header class="form-group">
            <h3 class="notes-title">
              {{ $t("admin.forms.entry") + ` (${resource.id})` }}
            </h3>
          </header>

          <pre v-if="resource.entryData">
            <form-row v-for="[name, value] in Object.entries(resource.entryData)" :title="name.startsWith('cf_') ? customFieldNames[name.slice(3)].name : $t(`clients.formFields.${name}`)">
              <span>{{ formatValue(value, name) }}</span>
            </form-row>
          </pre>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import useCore from "@/plugins/use-core"
import { useI18n } from "vue-i18n"
const { graphql, db } = useCore()
const { t } = useI18n()

const emit = defineEmits(["close"])
const { id } = defineProps<{
  id: Number
}>()

const loading = ref(true)
const resource = ref(null) as any

const fetchData = async () => {
  try {
    loading.value = true
    const { formEntry: data } = await graphql(`
      query formEntry {
        formEntry(id: ${id}) {
          id
          client { id name }
          entryData
          createdAt
        }
      }
    `)

    resource.value = data
    loading.value = false
  } catch (e) {
    console.error(e)
    close()
  }
}

const close = () => {
  emit("close")
}

const formatValue = (value, name) => {
  if (name === "salutation") return salutationOptions.value[value]
  if (name.startsWith("cf_") && customFieldNames.value[name.slice(3)].options?.length)
    return customFieldNames.value[name.slice(3)].options.find(option => option.id == value)?.name
  return value
}

const customFieldNames = computed(() => {
  const fields = db.shopData.customFieldGroupsForClients.flatMap(group => group.customFields)
  return fields.reduce(
    (obj, item) => Object.assign(obj, { [item.name]: { name: item.prettyName, options: item.customOptions } }),
    {}
  )
})

const salutationOptions = computed(() => {
  const defaultSalutations = [
    { id: "mr", name: "nameSalutationMr" },
    { id: "ms", name: "nameSalutationMs" },
    { id: "mrms", name: "nameSalutationMrms" },
  ]
  return defaultSalutations
    .map(o => ({ ...o, name: t(`detailView.${o.name}`) }))
    .concat(db.get("salutations").map(o => ({ id: o.internalName, name: o.name })))
    .reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {})
})

onMounted(() => {
  fetchData()
})
</script>
