<template>
  <nice-dialog
    width="50%"
    :model-value="visible"
    @update:model-value="emit('update:visible', $event)"
    :title="`${$t('integrations.extension-propgen.compare.title')} - ${originalImage[`title_${locale}`]}`"
  >
    <div class="flex flex-row justify-between">
      <span class="text-lg font-medium p-2">{{ $t("integrations.extension-propgen.compare.original") }}</span>
      <span class="text-lg font-medium p-2">{{ $t("integrations.extension-propgen.compare.generated") }}</span>
    </div>
    <div
      v-if="image.photo_url && originalImage?.photo_url"
      class="flex justify-center items-center propgen-compare-slider"
    >
      <ImageCompareSlider
        class="w-full max-w-[800px]"
        :image-one="originalImage.photo_url"
        :image-two="image.photo_url"
      />
    </div>
    <template #footer>
      <div class="p-2 w-full">
        <PropgenRateGeneratedImage v-if="image.ai_operation" :image="image" @submit="submitFeedback" />
      </div>
    </template>
  </nice-dialog>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from "vue"
import ImageCompareSlider from "@/components/media/ImageCompareSlider.vue"
import PropgenRateGeneratedImage from "./PropgenRateGeneratedImage.vue"
import { ImageTypeEnhanced } from "./propgen.types"
import { usePropgenExtension } from "./usePropgenExtension"
import { LocaleKey } from "@/core/i18n"

const { submitFeedback } = usePropgenExtension()

const {
  visible,
  image,
  originalImage,
  locale = "de",
} = defineProps<{
  visible: boolean
  image: ImageTypeEnhanced
  originalImage: ImageTypeEnhanced
  locale: LocaleKey
}>()

const emit = defineEmits(["update:visible", "submit"])
</script>

<style scoped>
.propgen-compare-slider :deep(img-comparison-slider) {
  --slider-color: var(--ps-ai-color-secondary);
  /* --slider-color: var(--ps-color-primary); */
  --divider-width: 1px;
  --divider-color: var(--slider-color);
  --default-handle-opacity: 1;
  --default-handle-color: var(--slider-color);
  --default-handle-width: 70px;
  --default-handle-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7);
}
</style>
