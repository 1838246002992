<script lang="ts" setup>
import { isDefined } from "@/config/isDefined"
import useCore from "@/plugins/use-core"
import { computed } from "vue"
import { useI18n } from "vue-i18n"

type MenuItem = {
  component?: string
  header?: boolean
  title?: string
  href?: string
  name?: string
  icon?: string
  sortSection?: string
  attributes?: { exact?: boolean }
}

type MenuItems = MenuItem[]

const { db } = useCore()
const { t } = useI18n()

const getMenuItems = ({ db }) => {
  const isAdmin = db.broker.admin

  return [
    { component: "SidebarSpacer" },
    { href: "/dashboard", name: "back", icon: "fal fa-long-arrow-left text-primary" },
    db.hasRight("rightToEditPortals") || db.hasRight("rightToEditExtensions") || db.hasRight("rightToEditMailAccounts")
      ? {
          header: true,
          name: "interfaces",
        }
      : undefined,
    db.hasRight("rightToEditPortals")
      ? {
          href: "/admin/connections",
          name: "portals",
          icon: "fal fa-plug",
        }
      : undefined,
    db.hasRight("rightToEditExtensions")
      ? { href: "/admin/extensions", name: "extensions", icon: "fal fa-plug" }
      : undefined,
    db.hasRight("rightToEditApiKeys")
      ? {
          href: "/admin/api_keys",
          name: "api_keys",
          icon: "fal fa-plug",
        }
      : undefined,
    db.hasRight("rightToEditBrokers")
      ? {
          href: "/admin/mail_accounts",
          name: "mailaccounts",
          icon: "fal fa-plug",
        }
      : undefined,
    { header: true, name: "company" },
    { href: "/admin/brokers", name: "brokers", icon: "fal fa-user", sortSection: "settings" },
    db.shopData.franchiser ? { href: "/admin/franchise", name: "franchise", icon: "fal fa-store" } : undefined,
    db.hasRight("rightToEditTeams")
      ? {
          href: "/admin/teams",
          name: "teams",
          icon: "fal fa-users-class",
        }
      : undefined,
    db.hasRight("rightToEditShopData")
      ? {
          href: "/admin/settings/shop",
          name: "company",
          icon: "fal fa-briefcase",
          sortSection: "settings",
        }
      : undefined,
    db.hasRight("rightToEditDepartments")
      ? {
          href: "/admin/departments",
          name: "departments",
          icon: "fal fa-users",
        }
      : undefined,
    db.hasRight("rightToEditRecipes")
      ? {
          href: "/admin/automations",
          name: "automations",
          icon: "fal fa-magic",
        }
      : undefined,
    isAdmin
      ? {
          href: "/admin/rights",
          name: "rights",
          icon: "fal fa-cog",
        }
      : undefined,
    {
      header: true,
      name: "settings",
    },
    db.hasRight("rightToEditTasksSettings")
      ? { href: "/admin/settings/tasks", name: "tasks", icon: "fal fa-tag", sortSection: "settings" }
      : undefined,

    db.hasRight("rightToEditTaskPipelinesSettings") && db.featureActive("task_pipelines")
      ? {
          href: "/admin/settings/task_pipelines",
          name: "task_pipelines",
          icon: "fal fa-filter",
          sortSection: "settings",
        }
      : undefined,
    (db.shopData.activeFeatures.includes("ph_valuation") || db.shopData.activeFeatures.includes("sn_valuation")) &&
    db.hasRight("rightToSeeMarketing")
      ? { href: "/admin/shop/valuations", name: "valuations", icon: "fal fa-star-half-alt", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditUnits")
      ? { href: "/admin/locations", name: "locations", icon: "fal fa-map-marker-alt", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditLetterTemplates")
      ? { href: "/admin/letter_templates", name: "letters", icon: "fal fa-file-word", sortSection: "settings" }
      : undefined,
    isAdmin
      ? { href: "/admin/custom_fields", name: "customfields", icon: "fal fa-wrench", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditGdprData")
      ? { href: "/admin/settings/gdpr", name: "gdpr", icon: "fal fa-shield-alt", sortSection: "settings" }
      : undefined,
    isAdmin
      ? {
          href: "/admin/settings/lead_inquiries",
          name: "leadinquiries",
          icon: "fal fa-smile-plus",
          sortSection: "settings",
        }
      : undefined,
    db.hasRight("rightToEditPropertyReportSettings")
      ? {
          href: "/admin/settings/property_report",
          name: "propertyreport",
          icon: "fal fa-user-chart",
          sortSection: "settings",
        }
      : undefined,
    { href: "/admin/settings/messages", name: "emails", icon: "fal fa-paper-plane", sortSection: "settings" },
    db.hasRight("rightToEditContactsSettings")
      ? { href: "/admin/settings/contacts", name: "contacts", icon: "fal fa-address-card", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditLandingpages")
      ? { href: "/admin/shop/micro_sites", name: "lps", icon: "fal fa-browser", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditLeadScoreSettings")
      ? { href: "/admin/shop/lead_score", title: "Lead-Score", icon: "fal fa-star-half-alt", sortSection: "settings" }
      : undefined,
    db.shopData.activeFeatures.includes("immo_valuation")
      ? {
          href: "/admin/shop/immo_valuation",
          title: "Leadfisher",
          icon: "fal fa-hand-holding-usd",
          sortSection: "settings",
        }
      : undefined,
    db.featureActive("tipster_portal")
      ? {
          href: "/admin/shop/tipster_portal",
          title: "Tipster Portal",
          icon: "fal fa-lightbulb",
          sortSection: "settings",
        }
      : undefined,
    db.hasRight("rightToEditMediaSettings")
      ? { href: "/admin/settings/media", name: "media", icon: "fal fa-image", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditGroups")
      ? { href: "/admin/super_groups", name: "supergroups", icon: "fal fa-tags", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditPropertySettings")
      ? { href: "/admin/settings/properties", name: "properties", icon: "fal fa-home", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditPdfExposeeSettings")
      ? { href: "/admin/shop/pdf_exposees", name: "exposees", icon: "fal fa-file-pdf", sortSection: "settings" }
      : undefined,
    isAdmin
      ? { href: "/admin/settings/deals", name: "deals", icon: "fal fa-dollar-sign", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditPipelines")
      ? { href: "/admin/settings/pipelines", name: "pipelines", icon: "fal fa-funnel-dollar", sortSection: "settings" }
      : undefined,
    isAdmin
      ? { href: "/admin/settings/reports", name: "reports", icon: "fal fa-chart-bar", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditSavedQuerySettings")
      ? {
          href: "/admin/settings/saved_queries",
          name: "queries",
          icon: "fal fa-search-location",
          sortSection: "settings",
        }
      : undefined,
    db.hasRight("rightToEditSettings")
      ? {
          href: "/admin/settings",
          name: "system",
          icon: "fal fa-cog",
          sortSection: "settings",
          attributes: { exact: true },
        }
      : undefined,
    db.hasRight("rightToEditUnits")
      ? { href: "/admin/shopwindows", name: "shopwindows", icon: "fal fa-window-restore", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToEditEventSettings")
      ? { href: "/admin/settings/events", name: "events", icon: "fal fa-calendar-alt", sortSection: "settings" }
      : undefined,
    db.hasRight("rightToChangeTasks")
      ? { href: "/admin/booking_calendars", name: "bookings", icon: "fal fa-calendar-plus", sortSection: "settings" }
      : undefined,
    { href: "/admin/snippet_categories", name: "snippets", icon: "fal fa-font", sortSection: "settings" },
    db.featureActive("form_builder")
      ? { href: "/admin/custom_forms", name: "forms", icon: "fal fa-font", sortSection: "settings" } // TODO: find icon
      : undefined,
    { component: "SidebarEnder" },
  ]
}

const menu = computed(() => {
  const menuItems: MenuItems = getMenuItems({ db }).filter(isDefined)

  return menuItems
    .map(menuItem => {
      let { title, name } = menuItem

      if (!title && name) {
        title = t(`adminSidebar.${menuItem?.name}`)
      }

      return { ...menuItem, title }
    })
    .reduce((previous, current) => {
      if (previous.length === 0 || current?.sortSection !== previous[previous.length - 1]?.[0]?.sortSection) {
        previous.push([])
      }

      previous[previous.length - 1].push(current)

      return previous
    }, [] as MenuItem[][])
    .flatMap(item => {
      return item[0].sortSection ? item.sort((a, b) => a.title!.localeCompare(b.title!)) : item
    })
})
</script>

<template>
  <nav class="page-sidebar bg-gray-50">
    <div class="sidebar-menu">
      <sidebar-menu :menu="menu" :show-one-child="true" />
    </div>
  </nav>
</template>
