import { ImageType } from "@/components/media/media.types"
import { createWebhookUrl } from "@/config/url"
import {
  IntegrationOrderCreateDataPropgenEnhance,
  IntegrationOrderCreateDataPropgenStage,
  IntegrationOrderCreateDataPropgenVideoReel,
} from "@/integrations/types/integration-propgen"

export function createImageOperationOrderData(
  images: ImageType[],
  config: any,
  operation: "virtual_staging" | "image_enhancement",
  callbackParams?: any,
  orderData?: any
): IntegrationOrderCreateDataPropgenEnhance | IntegrationOrderCreateDataPropgenStage | undefined {
  if (!images.length || !operation || !config) {
    console.warn("no configs or operation provided", images, config, operation)
    return
  }

  const imageConfigs = images.map(image => ({
    ...config,
    image_id: image.token, // image_id is a token here (not sure why this is necessary, we could also use ID?)
    image_url: image.photo_url,
  }))

  const callbackUrl = createWebhookUrl("propgen", { ...callbackParams })

  // Create the order data
  let result = {
    operation,
    images: imageConfigs,
    webhook_url: callbackUrl,
    ...orderData,
  }

  // More specific type assertion based on operation
  if (operation === "image_enhancement") {
    return result as IntegrationOrderCreateDataPropgenEnhance
  } else {
    return result as IntegrationOrderCreateDataPropgenStage
  }
}

export function createVideoOperationOrderData(
  images: ImageType[],
  config: any,
  operation: "reel",
  callbackParams: any
): IntegrationOrderCreateDataPropgenVideoReel {
  const callbackUrl = createWebhookUrl("propgen", { ...callbackParams })

  const templateSlots = config.templateSlots.reduce((acc: any, slot: any) => {
    const image = images.find(img => img.id === slot.imageId)
    acc[slot.slotId] = image?.photo_url
    return acc
  }, {})

  const { templateSlots: _, ...videoConfig } = config

  let orderData = {
    operation,
    webhook_url: callbackUrl,
    ...videoConfig,
    ...templateSlots,
  }
  return orderData as IntegrationOrderCreateDataPropgenVideoReel
}
