<template>
  <form-section>
    {{ t(`integrations.extension-propgen.reel.explanation`) }}
    {{
      t(`integrations.extension-propgen.generic.estimatedDuration`, {
        value: t(`integrations.extension-propgen.reel.estimatedDuration`),
      })
    }}
  </form-section>
  <form-section>
    <div class="flex flex-row w-full gap-4 mt-4 mb-4">
      <!-- Template slots as drop targets -->
      <div
        v-for="slot in videoReelTemplate.slots"
        :key="slot.id"
        class="flex-1 bg-slate-200 h-32 p-2 rounded-md shadow-inner flex flex-col items-center justify-center relative transition-all duration-200"
        :class="{
          'border-2 border-dashed border-blue-400 bg-blue-50 scale-[1.02]': draggedOverSlot === slot.id,
          'border-2 border-transparent': draggedOverSlot !== slot.id,
        }"
        @dragover.prevent="handleDragOver($event, slot.id)"
        @dragleave.prevent="handleDragLeave($event, slot.id)"
        @dragenter.prevent="handleDragEnter($event, slot.id)"
        @drop="handleDrop($event, slot.id)"
      >
        <div
          v-if="getImageForSlot(slot.id)"
          class="w-full h-full relative"
          draggable="true"
          @dragstart="startDragFromSlot($event, getImageForSlot(slot.id), slot.id)"
        >
          <img :src="getImageForSlot(slot.id)?.photo_url_thumb" class="w-full h-full object-cover rounded" />
          <button
            @click="removeFromSlot(slot.id)"
            class="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
          >
            ×
          </button>
        </div>
        <span v-else class="text-lg font-bold text-slate-400">{{ slot.name }}</span>
      </div>
    </div>
  </form-section>

  <form-section>
    <div class="w-full overflow-x-auto flex flex-row flex-nowrap gap-4 pb-4">
      <div
        v-for="image in props.images"
        :key="image.id"
        class="flex-shrink-0 w-32 h-24 relative rounded overflow-hidden border border-gray-200 group"
        draggable="true"
        @dragstart="startDragFromGallery($event, image)"
      >
        <img :src="image.photo_url_thumb" :alt="image[`title_${locale}`]" class="w-full h-full object-cover" />
        <div
          class="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity flex items-end p-2"
        >
          <span class="text-white text-xs truncate w-full">
            {{ image[`title_${locale}`] || "Unbetiteltes Bild" }}
          </span>
        </div>
        <div
          v-if="getImageUsageCount(image.id) > 0"
          class="absolute top-1 right-1 bg-blue-500 rounded-full min-w-5 h-5 flex items-center justify-center px-1.5"
        >
          <span class="text-white text-xs">{{ getImageUsageCount(image.id) }}</span>
        </div>
      </div>
    </div>
  </form-section>

  <form-section>
    <div class="mb-2">{{ t(`integrations.extension-propgen.reel.config.ratios.label`) }}</div>
    <nice-radio-group v-model="props.modelValue.ratio" :options="translatedVideoReelOptions.ratios" />
  </form-section>
  <form-section>
    <div class="mb-2">{{ t(`integrations.extension-propgen.reel.config.headline`) }}</div>
    <nice-input v-model="props.modelValue.headline" :show-word-limit="true" :maxlength="70" />
    <div class="mb-2">{{ t(`integrations.extension-propgen.reel.config.subline`) }}</div>
    <nice-input v-model="props.modelValue.subline" :show-word-limit="true" :maxlength="30" />
    <div class="mb-2">{{ t(`integrations.extension-propgen.reel.config.kpi`) }}</div>
    <nice-textarea v-model="props.modelValue.kpi" :show-word-limit="true" :maxlength="125" />
  </form-section>
  <form-section>
    <div class="mb-2">{{ t(`integrations.extension-propgen.reel.config.useVoiceNarration`) }}</div>
    <nice-checkbox v-model="voiceNarration" />
    <template v-if="voiceNarration">
      <div class="mb-2">{{ t(`integrations.extension-propgen.reel.config.narration`) }}</div>
      <nice-textarea v-model="props.modelValue.narration" :show-word-limit="true" :maxlength="320" />
      <div class="mb-2">{{ t(`integrations.extension-propgen.reel.config.voice`) }}</div>
      <nice-radio-group v-model="props.modelValue.voice" :options="translatedVideoReelOptions.voices" />
    </template>
  </form-section>
</template>

<script setup lang="ts">
import { defineProps, watch, ref } from "vue"
import { VideoReelTemplateDefinition, videoReelOptions } from "../propgen.constants"
import { ImageTypeEnhanced } from "../propgen.types"
import { useLocale } from "@/core/i18n"
import { useI18n } from "vue-i18n"
import { PropgenImageOperation, PropgenImageOperationConfigVideoReel } from "@/integrations/types/integration-propgen"

const { t } = useI18n()
const locale = useLocale()

const translatedVideoReelOptions = {
  ratios: videoReelOptions.ratios.map(ratio => ({
    ...ratio,
    name: t(`integrations.extension-propgen.reel.config.ratios.values.${ratio.id}`),
  })),
  voices: videoReelOptions.voices.map(voice => ({
    ...voice,
    name: t(`integrations.extension-propgen.reel.config.voices.values.${voice.id}`),
  })),
}

export type TemplateSlot = { slotId: string; imageId: number }

export type VideoReelConfigurableProps = Pick<
  PropgenImageOperationConfigVideoReel,
  "ratio" | "headline" | "subline" | "kpi" | "narration" | "voice"
>

export type VideoReelFormProps = {
  modelValue: {
    templateSlots: Array<TemplateSlot>
  } & VideoReelConfigurableProps
  operation: PropgenImageOperation
  videoReelTemplate: VideoReelTemplateDefinition
  images: ImageTypeEnhanced[]
}

const voiceNarration = ref(false)

const props = defineProps<VideoReelFormProps>()

const emit = defineEmits(["update:modelValue"])

watch(voiceNarration, shouldUse => {
  if (!shouldUse) {
    emit("update:modelValue", {
      ...props.modelValue,
      narration: null,
      voice: null,
    })
  } else {
    emit("update:modelValue", {
      ...props.modelValue,
      narration: "",
      voice: "female",
    })
  }
})

// Initialize templateSlots if it doesn't exist
if (!props.modelValue.templateSlots) {
  emit("update:modelValue", {
    ...props.modelValue,
    templateSlots: [],
  })
}

// Add a ref to track which slot is currently being dragged over
const draggedOverSlot = ref(null)
// Add a ref to track source slot when dragging from a slot
const dragSourceSlot = ref(null)
// Add a ref to track the dragged image ID
const draggedImageId = ref(null)
// Add a ref to track if we're dragging from gallery or from a slot
const dragSource = ref<"gallery" | "slot" | null>(null) // 'gallery' or 'slot'

// Handle drag start from gallery
const startDragFromGallery = (event, image) => {
  event.dataTransfer.dropEffect = "copy"
  event.dataTransfer.effectAllowed = "copy"
  event.dataTransfer.setData("text/plain", image.id.toString())
  draggedImageId.value = image.id
  dragSourceSlot.value = null
  dragSource.value = "gallery"
}

// Handle drag start from a slot
const startDragFromSlot = (event, image, slotId) => {
  event.dataTransfer.dropEffect = "move"
  event.dataTransfer.effectAllowed = "move"
  event.dataTransfer.setData("text/plain", image.id.toString())
  draggedImageId.value = image.id
  dragSourceSlot.value = slotId
  dragSource.value = "slot"
}

// Handle drag enter
const handleDragEnter = (event, slotId) => {
  draggedOverSlot.value = slotId
}

// Handle drag over
const handleDragOver = (event, slotId) => {
  event.dataTransfer.dropEffect = dragSource.value === "slot" ? "move" : "copy"
  draggedOverSlot.value = slotId
}

// Handle drag leave
const handleDragLeave = (event, slotId) => {
  // Check if we're actually leaving the element (not entering a child)
  if (event.currentTarget.contains(event.relatedTarget)) {
    return
  }
  draggedOverSlot.value = null
}

// Update the drop handler to reset the dragged over state
const handleDrop = (event, slotId) => {
  draggedOverSlot.value = null

  // Get the image ID from dataTransfer or from our ref
  let imageId
  try {
    imageId = Number(event.dataTransfer.getData("text/plain"))
    if (!imageId) imageId = draggedImageId.value
  } catch (e) {
    imageId = draggedImageId.value
  }

  if (!imageId) return

  // Process based on drag source
  if (dragSource.value === "slot") {
    // Moving between slots
    moveOrSwapBetweenSlots(dragSourceSlot.value, slotId)
  } else {
    // Coming from gallery - can assign to multiple slots
    assignToSlot(slotId, imageId)
  }

  // Reset drag tracking
  dragSourceSlot.value = null
  draggedImageId.value = null
  dragSource.value = null
}

// New function to move or swap between slots
const moveOrSwapBetweenSlots = (sourceSlotId, targetSlotId) => {
  // If source and target are the same, do nothing
  if (sourceSlotId === targetSlotId) return

  // Create new array to ensure reactivity
  let updatedSlots = [...props.modelValue.templateSlots]

  // Find existing assignments
  const sourceIndex = updatedSlots.findIndex(item => item.slotId === sourceSlotId)
  const targetIndex = updatedSlots.findIndex(item => item.slotId === targetSlotId)

  if (targetIndex >= 0) {
    // Target slot has an image - swap the image IDs
    const targetImageId = updatedSlots[targetIndex].imageId
    const sourceImageId = updatedSlots[sourceIndex].imageId

    // Update the image IDs (not the slot IDs)
    updatedSlots[sourceIndex].imageId = targetImageId
    updatedSlots[targetIndex].imageId = sourceImageId
  } else {
    // Target slot is empty - create a new assignment
    updatedSlots.push({ slotId: targetSlotId, imageId: updatedSlots[sourceIndex].imageId })
    // Remove the source assignment
    updatedSlots.splice(sourceIndex, 1)
  }

  // Update the model
  emit("update:modelValue", {
    ...props.modelValue,
    templateSlots: updatedSlots,
  })
}

// New function to assign from gallery to slot
const assignToSlot = (slotId, imageId) => {
  // Create new array to ensure reactivity
  const updatedSlots = [...props.modelValue.templateSlots]

  // Remove any image currently in this slot
  const slotIndex = updatedSlots.findIndex(item => item.slotId === slotId)
  if (slotIndex >= 0) {
    updatedSlots.splice(slotIndex, 1)
  }

  // Add the new assignment
  updatedSlots.push({ slotId, imageId })

  // Update the model
  emit("update:modelValue", {
    ...props.modelValue,
    templateSlots: updatedSlots,
  })
}

// Remove image from a slot
const removeFromSlot = slotId => {
  const updatedSlots = props.modelValue.templateSlots.filter(item => item.slotId !== slotId)

  emit("update:modelValue", {
    ...props.modelValue,
    templateSlots: updatedSlots,
  })
}

// Get image assigned to a specific slot
const getImageForSlot = slotId => {
  const assignment = props.modelValue.templateSlots?.find(item => item.slotId === slotId)
  return assignment ? props.images.find(img => img.id === assignment.imageId) : null
}

// Get count of slots where this image is assigned
const getImageUsageCount = imageId => {
  return props.modelValue.templateSlots?.filter(item => item.imageId === imageId).length || 0
}

watch(
  () => props.modelValue,
  newValue => {
    emit("update:modelValue", newValue)
  },
  { deep: true }
)
</script>

<style scoped>
[draggable="true"] {
  cursor: move;
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
</style>
