<template>
  <card class="px-4 py-1">
    <header class="content-header d-flex justify-content-between mx-0 mb-2">
      <a href="#" @click.prevent="$emit('close')" class="app-pane-header-button left">
        <fa-icon name="times" />
      </a>
      <a href="#" @click.prevent="submit" class="app-pane-header-button right" :class="{ loading: submitting }">
        {{ $t("inbox.clientForm.new") }}
      </a>
    </header>

    <div class="app-panel-body">
      <signature-analyzer v-model:record="record" class="mb-3" />
      <section class="mb-4">
        <dv-row :label="$t('inbox.clientForm.reqProp')" v-if="!!record.attached_property_ids">
          <property-select v-model="record.attached_property_ids" multiple />
        </dv-row>
        <dv-row :label="$t('inbox.clientForm.reqProj')" v-if="!!record.project_ids">
          <project-select v-model="record.project_ids" multiple />
        </dv-row>
      </section>
      <section v-for="(group, idx) in groups" :key="idx" class="mb-4">
        <base-field v-for="field in group.fields" :key="field.fieldName" :field="field" :record="record" />
      </section>
    </div>
  </card>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { DEFAULT_MAIL_CLIENT_FIELDS } from "@/config/client/default-client-fields"
import detailViewForm from "@/config/detail-view-form"
import FormFields from "@/config/client/form-fields"
import { formatPayload } from "@/config/client/format-payload"
import { DetailViewGroup, Field, FormField, Group } from "@/interfaces"
import BASE_CLIENT from "@/config/client/base-client"
import SignatureAnalyzer from "@/components/client/SignatureAnalyzer.vue"
import { snakeCase } from "@/utils/with-case"

export default defineComponent({
  props: {
    message: Object as PropType<any>,
    task: Object as PropType<any>,
    mlsProperty: Object as PropType<any>,
    initialData: {
      type: Object as PropType<any>,
    },
  },
  components: {
    SignatureAnalyzer,
  },
  data() {
    if (this.initialData.partial_custom_fields) {
      var formattedInitialData = {
        ...this.initialData,
        ..._.mapKeys(this.initialData.partial_custom_fields, (v, k) => snakeCase(`cf_${k}`)),
      }
    } else {
      var formattedInitialData = this.initialData
    }

    return {
      submitting: false,
      record: {
        ...BASE_CLIENT,
        ...formattedInitialData,
        group_ids: formattedInitialData.owner
          ? this.$db.shopData.clientGroups.filter(o => o.name == "Eigentümer").map(o => o.id)
          : [],
        inquiry_phone_number: undefined,
        owner: undefined,
        attached_property_ids: this.message?.properties.map(o => Number(o.id)),
        project_ids: this.message?.projects.map(o => Number(o.id)),
      },
    }
  },
  methods: {
    submit() {
      if (this.checkMandatoryFields()) {
        $("[data-submit]").removeClass("loading")
        return
      }
      this.submitting = true
      this.$axios
        .post("/services/contacts", {
          client: formatPayload(this.record),
          message_id: this.message?.id,
          task_id: this.task?.id,
          mls_property_id: this.mlsProperty?.id,
        })
        .then(_ => {
          App.flashy(this.$t("inbox.clientForm.success"))
          this.$emit("create")
        })
        .catch(this.$axios.handleError)
        .finally(() => {
          this.submitting = false
        })
    },
    setDefaultPhoneNumberInCorrectField(number: number) {
      const fieldNames = _.flatten(this.groups.map(o => o.fields.map(f => f.fieldName)))
      if (fieldNames.includes("phone_numbers")) {
        this.record.client_addresses.push({
          value: number,
          label: "",
        })
      } else if (fieldNames.includes("home_cell")) {
        this.record.home_cell = number
      }
    },
    checkMandatoryFields() {
      if (this.mandatoryFields?.length < 1) return false
      const emptyFields = this.mandatoryFields.filter(f => {
        const value = this.record[f.fieldName]
        if (Array.isArray(value)) {
          return value.length === 0
        } else {
          return [undefined, null, ""].includes(value)
        }
      })

      if (emptyFields.length > 0) {
        App.alert(
          this.$t("propertyForm.missingMandatoryFields", {
            fields: emptyFields.map(f => f.formField.label).join(", "),
          }) as string
        )
        return true
      }
      return false
    },
  },
  computed: {
    clientDetailViewGroups(): DetailViewGroup[] {
      const customDetailViewGroups = this.$db.shopData.clientMailboxDetailViewGroups
      if (customDetailViewGroups.length > 0) return customDetailViewGroups
      return DEFAULT_MAIL_CLIENT_FIELDS
    },
    formFields(): Record<string, FormField> {
      return Object.fromEntries(
        Object.entries(FormFields).map(([key, value]) => {
          let translated = { ...value, label: this.$t(`clients.formFields.${snakeCase(value.filterName || key)}`) }
          if (!!value.options && Array.isArray(value.options)) {
            translated.options = value.options.map(o =>
              o.translate ? { id: o.id, name: this.$t(`clients.formFieldOptions.${o.name}`) } : o
            ) as any
          }
          return [key, translated]
        })
      ) as Record<string, FormField>
    },
    groups(): Group[] {
      return detailViewForm(
        this.formFields,
        this.clientDetailViewGroups,
        false,
        this.$db,
        this.$db.shopData.customFieldGroupsForClients
      )
    },
    mandatoryFields(): Field[] {
      return this.groups
        .map(g => g.fields)
        .flat()
        .filter(g => g.mandatory && !g.readonly)
    },
  },
  mounted() {
    if (this.initialData.inquiry_phone_number) {
      this.setDefaultPhoneNumberInCorrectField(this.initialData.inquiry_phone_number)
    }
  },
})
</script>
