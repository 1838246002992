<template>
  <div
    :data-id="image.id"
    class="property-image"
    :class="{
      'is-floorplan': image.is_floorplan,
      'is-private': image.is_private,
      'is-selected': selected,
      'can-edit': canEdit,
      'ai-gradient': image.ai_original_image_id || image.__is_pending,
    }"
  >
    <template v-if="canEdit && !image.__is_pending"">
      <div class="image-bg" :style="{ 'background-image': `url('${image.photo_url_thumb}')` }"></div>
      <div class="title-container">
        <input
          type="text"
          placeholder="Bildbeschreibung"
          class="image-title"
          v-model="image[`title_${locale}`]"
          @keydown.enter="$event.target?.blur()"
          @focus="$event.target?.select()"
          @change="updateTitle"
        />
        <div class="show-on-image-hover">
          <PropgenImageOperations
            v-if="isPropgenEnabled && !image.ai_operation"
            :images="[image]"
            @submitted-image-operation="$emit('submitted-image-operation', $event)"
          />
          <div v-else-if="isPropgenEnabled && image.ai_operation && ['image_enhancement', 'virtual_staging'].includes(image.ai_operation)">
            <div v-if="relatedImages?.length" class="p-2 bg-white/80 absolute top-0 right-0 cursor-pointer" @click="showImageCompareDialog = true">
              <fa-icon name="sliders-h" />
            </div>
          </div>
        </div>
      </div>
      <div class="image-actions">
        <a tabindex="-1" href="#" @click.prevent="$emit('edit')" class="action-trigger edit-trigger">
          <fa-icon name="pencil" />
        </a>
        <a tabindex="-1" href="#" @click.prevent="$emit('delete')" class="action-trigger delete-trigger">
          <fa-icon name="trash-alt" />
        </a>
        <a tabindex="-1" href="#" @click.prevent="$emit('fullscreen')" class="action-trigger fullscreen-trigger">
          <fa-icon name="expand-alt" />
        </a>
        <a
          tabindex="-1"
          href="#"
          @click.prevent="$emit('watermark')"
          class="action-trigger watermark-trigger"
          v-if="$db.shopData.watermarkOnPortals && !image.disable_watermarking"
          title="Bild mit Wasserzeichen herunterladen"
        >
          <fa-icon name="tint" />
        </a>
      </div>
      <span class="image-indicators">
        <span class="image-floorplan-indicator" title="Grundriss">
          <fa-icon name="expand-wide" />
        </span>
        <span class="image-private-indicator" title="Privat">
          <fa-icon name="lock" />
        </span>
      </span>
    </template>
    <template v-else>
      <ProgressImage class="image-bg" v-if="image.__is_pending" :imageUrl="image.photo_url_thumb" :loading="true" />
      <div v-else class="image-bg" :style="{ 'background-image': `url('${image.photo_url_thumb}')` }"></div>
      <p class="image-title">{{ image[`title_${locale}`] }}</p>
      <div class="image-actions">
        <a
        href="#"
        v-if="!image.__is_pending"
        @click.prevent="$emit('fullscreen')"
        class="action-trigger fullscreen-trigger"
        >
        <fa-icon name="expand-alt" />
      </a>
      </div>
    </template>
  </div>
  <PropgenImageCompare
    v-if="showImageCompareDialog && isPropgenEnabled && image && originalImage"
    :visible="showImageCompareDialog"
    :image="image"
    :locale="locale"
    :originalImage="originalImage"
    @update:visible="showImageCompareDialog = $event"
  />
</template>

<script lang="ts" setup>
import { LocaleKey } from "@/core/i18n"
import useCore from "@/plugins/use-core"
import PropgenImageOperations from "@/components/integrations/propgen/PropgenImageOperations.vue"
import PropgenImageCompare from "@/components/integrations/propgen/PropgenImageCompare.vue"
import ProgressImage from "@/components/ProgressImage.vue"
import { ImageTypeEnhanced } from "@/components/integrations/propgen/propgen.types"
import { usePropgenExtension } from "@/components/integrations/propgen/usePropgenExtension"

import { ImageType } from "./media.types"
import { ref } from "vue"

export type ImageItemProps = {
  image: ImageTypeEnhanced
  originalImage?: ImageType
  relatedImages?: ImageTypeEnhanced[]
  selected?: boolean
  canEdit?: boolean
  locale: LocaleKey
}

const emit = defineEmits(["submitted-image-operation", "edit", "delete", "fullscreen", "watermark"])
const { axios } = useCore()

const { isPropgenEnabled } = usePropgenExtension()
const showImageCompareDialog = ref(false)

const { image, locale, originalImage, relatedImages, selected, canEdit } = defineProps<ImageItemProps>()

const updateTitle = () => {
  axios.put(`/portfolio/images/${image.id}`, {
    image: { [`title_${locale}`]: image[`title_${locale}`] },
  })
}
</script>

<style lang="scss" scoped>
.ai-gradient {
  background: var(--ps-ai-gradient);
}
.property-image .show-on-image-hover {
  visibility: hidden;
}
.property-image:hover .show-on-image-hover {
  visibility: visible;
}
.property-image {
  width: 170px;
  height: 170px;
  margin: 1rem 1rem 0 0;
  outline: 1px solid #dedede;
  border-radius: 3px;
  padding: 2px;
  position: relative;

  &.is-selected {
    outline: 3px solid rgb(169, 206, 255);
  }
}
.title-container {
  position: relative;
}

.property-image.image-container {
  background: #fff;
}

.property-image .image-bg {
  width: 100%;
  height: calc(100% - 36px);
  background-size: cover;
  background-position: center;
  position: relative;
}

.property-image .action-trigger {
  position: absolute;
  top: 10px;
  width: 30px;
  height: 30px;
  background: #000;
  padding-top: 6px;
  border-radius: 2px;
  opacity: 0.7;
  color: white;
  text-align: center;
  display: none;
}

.property-image .delete-trigger {
  right: 10px;
}

.property-image .edit-trigger {
  left: 10px;
}

.property-image .fullscreen-trigger {
  top: initial;
  right: 10px;
  bottom: 46px;
}

.property-image .watermark-trigger {
  left: 55px;
}

.property-image .image-indicators {
  position: absolute;
  left: 10px;
  bottom: 46px;
  line-height: 30px;
  color: white;
}

.property-image .image-floorplan-indicator {
  background: rgba(0, 0, 0, 0.7);
  margin-right: 10px;
  padding: 0 6px;
  display: none;
}

.property-image .image-private-indicator {
  background: rgba(0, 0, 0, 0.7);
  padding: 0 6px;
  display: none;
}

.property-image:hover .action-trigger {
  display: block;
}

.property-image .image-title {
  width: 100%;
  height: 36px;
  padding: 8px;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  background: #fff;
}

.property-image.is-title-image::before {
  content: "Titelbild";
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%);
  background-color: #0f55eb;
  color: white;
  font-weight: 400;
  font-size: 10px;
  padding: 2px 12px;
  z-index: 1;
}

.property-image.is-floorplan .image-floorplan-indicator,
.property-image.is-private .image-private-indicator {
  display: inline-block;
}
</style>
