import { Integration } from "../types/integration-service"

const mocks: Integration[] = [
  {
    id: "extension-propgen-ps",
    type: "extension",
    category: "services",
    products: ["propstack"],
    defaults: {
      enabled: true,
    },
    description: "Mock for Propgen Extension for local development",
    fixedConfiguration: {
      requiresConfirmOnDisable: false,
      requiresConfirmLegalConsent: false,
      hidden: false,
    },
    configuration: {
      enabled: true,
      widgetHidden: false,
    },
    integrationName: "propgen",
    crmSystem: "PROPSTACK",
  },
] as Integration[]

export default mocks
