<template>
  <dropdown trigger="hover" :offset="1" width="270">
    <template #reference>
      <slot name="reference">
        <div class="p-2 bg-white/80 absolute top-0 right-0 cursor-pointer">
          <fa-icon name="magic" class="color-effect" />
        </div>
      </slot>
    </template>
    <dropdown-section>
      <dropdown-item
        v-if="operations.includes('image_enhancement')"
        @click="selectedImageOperation = 'image_enhancement'"
        class="px-3 w-100 flex justify-between"
      >
        {{ t("integrations.extension-propgen.image_enhancement.action") }}
        <span class="text-gray-400 text-sm">
          (~ {{ t("integrations.extension-propgen.image_enhancement.estimatedDuration") }})
        </span>
      </dropdown-item>
      <dropdown-item
        v-if="operations.includes('virtual_staging')"
        @click="selectedImageOperation = 'virtual_staging'"
        class="px-3 w-100 flex justify-between"
      >
        {{ t("integrations.extension-propgen.virtual_staging.action") }}
        <span class="text-gray-400 text-sm">
          (~ {{ t("integrations.extension-propgen.virtual_staging.estimatedDuration") }})
        </span>
      </dropdown-item>
    </dropdown-section>
  </dropdown>
  <PropgenDialogEnhance
    operation="image_enhancement"
    :visible="selectedImageOperation === 'image_enhancement'"
    :images="images"
    @update:visible="selectedImageOperation = $event"
    :onSubmit="handleOperationSubmit"
    @complete="handleOperationSubmitComplete"
  />
  <PropgenDialogStage
    operation="virtual_staging"
    :visible="selectedImageOperation === 'virtual_staging'"
    :images="images"
    @update:visible="selectedImageOperation = $event"
    :onSubmit="handleOperationSubmit"
    @complete="handleOperationSubmitComplete"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { ref } from "vue"
import { ImageTypeEnhanced } from "./propgen.types"
import { usePropgenExtension } from "./usePropgenExtension"
import { PropgenImageOperation } from "@/integrations/types/integration-propgen"
import PropgenDialogStage from "./stage/PropgenDialogStage.vue"
import PropgenDialogEnhance from "./enhance/PropgenDialogEnhance.vue"
import { createImageOperationOrderData } from "./propgen.utils"
import { useBroker } from "@/composables/use-broker"
import { get } from "@vueuse/core"
const { t } = useI18n()
const { broker } = useBroker()
const { dispatchOperation } = usePropgenExtension()

const { images, operations = ["image_enhancement", "virtual_staging"] } = defineProps<{
  images: ImageTypeEnhanced[]
  operations?: PropgenImageOperation[]
}>()

const selectedImageOperation = ref<PropgenImageOperation | null>(null)

const emit = defineEmits(["submitted-image-operation"])

export type SubmitOperationParams = {
  operation: "image_enhancement" | "virtual_staging"
  images: ImageTypeEnhanced[]
  config: any
}

export type SubmitOperationResultParams = {
  operation: "image_enhancement" | "virtual_staging"
  images: ImageTypeEnhanced[]
  data: any
  result: any
}

const handleOperationSubmit = async ({ operation, images, config }: SubmitOperationParams) => {
  const broker_id = get(broker)?.id
  const newConfig = { ...config }

  const data = createImageOperationOrderData(images, newConfig, operation, undefined, { broker_id })
  const result = await dispatchOperation(data, operation)

  selectedImageOperation.value = null

  // this result will be passed to @complete event from form dialog
  return { operation, images, data, result }
}

const handleOperationSubmitComplete = async ({ operation, images, data, result }: SubmitOperationResultParams) => {
  // receives the result from the form dialog complete
  console.log("handleOperationSubmitComplete", { operation, images, data, result })
  emit("submitted-image-operation", { operation, images, data, result })
}
</script>

<style scoped>
.color-effect {
  background: var(--ps-ai-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
