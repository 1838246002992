export type VideoReelTemplateDefinition = {
  slots: Array<{ id: string; name: string }>
}
export const videoReelTemplate: VideoReelTemplateDefinition = {
  slots: [
    {
      id: "estate_1_image_url",
      name: "Slot 1",
    },
    {
      id: "estate_2_image_url",
      name: "Slot 2",
    },
    {
      id: "estate_3_image_url",
      name: "Slot 3",
    },
    {
      id: "estate_4_image_url",
      name: "Slot 4",
    },
    {
      id: "estate_5_image_url",
      name: "Slot 5",
    },
  ],
}

export const videoReelOptions = {
  ratios: [
    { id: "landscape", name: "Landscape" },
    { id: "portrait", name: "Portrait" },
    { id: "square", name: "Square" },
  ],
  voices: [
    { id: "male", name: "Männlich" },
    { id: "female", name: "Weiblich" },
  ],
}

export const enhanceOptions = {
  enhanceType: [
    { id: "warm", name: "Warm" },
    { id: "neutral", name: "Neutral" },
  ],
  cloudType: [
    { id: "CLEAR", name: "Clear" },
    { id: "LOW_CLOUD", name: "Low Cloud" },
    { id: "HIGH_CLOUD", name: "High Cloud" },
  ],
}

export const stageOptions = {
  roomTypes: [
    { id: "living", name: "Wohnzimmer" },
    { id: "bed", name: "Schlafzimmer" },
    { id: "kids_room", name: "Kinderzimmer" },
    { id: "dining", name: "Esszimmer" },
    { id: "kitchen", name: "Küche" },
    { id: "home_office", name: "Homeoffice" },
    { id: "outdoor", name: "Garten" },
    { id: "bathroom", name: "Badezimmer" },
    { id: "hallway", name: "Flur" },
    { id: "garage", name: "Garage" },
    { id: "basement", name: "Keller" },
    { id: "attic", name: "Dachboden" },
  ],
  designStyles: [
    { id: "modern", name: "Modern" },
    { id: "scandinavian", name: "Skandinavisch" },
    { id: "standard", name: "Standard" },
    { id: "industrial", name: "Industriell" },
    { id: "luxury", name: "Luxus" },
    { id: "farmhouse", name: "Landhaus" },
    { id: "coastal", name: "Strandhaus" },
  ],
}
