<template>
  <div>
    <GradientBorderButton @click="videoCompositorOpen = true" class="mr-2" background="var(--ps-ai-gradient)">
      <fa-icon name="video"></fa-icon>
      {{ t("integrations.extension-propgen.reel.action") }}
    </GradientBorderButton>
    <PropgenDialogVideoReel
      operation="reel"
      :visible="videoCompositorOpen"
      :images="images"
      @update:visible="videoCompositorOpen = $event"
      :onSubmit="handleOperationSubmit"
      @complete="handleOperationSubmitComplete"
    />
  </div>
</template>

<script setup lang="ts">
import { ImageType } from "@/components/media/media.types"
import { ref } from "vue"
import GradientBorderButton from "../GradientBorderButton.vue"
import { usePropgenExtension } from "../usePropgenExtension"
import PropgenDialogVideoReel from "./PropgenDialogVideoReel.vue"
import { createVideoOperationOrderData } from "../propgen.utils"
import useShop from "@/plugins/use-shop"
import { useBroker } from "@/composables/use-broker"
import { get } from "@vueuse/core"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

export type SubmitVideoOperationParams = {
  operation: "reel"
  images: ImageType[]
  config: any
}

export type SubmitVideoOperationResultParams = {
  operation: "reel"
  images: ImageType[]
  data: any
  result: any
}

const {
  images = [],
  resourceId,
  resourceType,
} = defineProps<{ images: ImageType[]; resourceId: number; resourceType: "project" | "property" }>()

const emit = defineEmits(["submitted-video-operation"])
const { shop } = useShop()
const { broker } = useBroker()
const { dispatchOperation } = usePropgenExtension()

const videoCompositorOpen = ref(false)

const handleOperationSubmit = async ({ operation, images, config }: SubmitVideoOperationParams) => {
  if (!resourceId || !resourceType) {
    throw new Error("projectId or propertyId must be provided")
  }

  const website = get(shop)?.homepage ?? ""
  const logo_url = get(shop)?.logoUrl ?? ""
  const broker_id = get(broker)?.id

  const newConfig = { ...config, website, logo_url, broker_id }

  const data = createVideoOperationOrderData(images, newConfig, operation, {
    resource_id: resourceId,
    resource_type: resourceType,
  })

  const result = await dispatchOperation(data, operation)

  // emit something to allow consumers to update state (temp placeholder) with info
  videoCompositorOpen.value = false

  return { operation, images, data, result }
}

const handleOperationSubmitComplete = async ({ operation, images, data, result }: SubmitVideoOperationResultParams) => {
  // receives the result from the form dialog complete
  emit("submitted-video-operation", { operation, images, data, result })
}
</script>
