import { MaybeRef } from "@vueuse/core"
import { useIntegrationOrderDispatchMutation } from "./mutations/use-integration-order-dispatch-mutation"
import { IntegrationOrder, IntegrationOrderCreateData, IntegrationOrderStatus } from "./types/integration-order-service"
import { IntegrationId } from "./types/integration-service"
import { useNotifications } from "./use-notifications"
import { useTracking } from "./use-tracking"
import { useI18n } from "vue-i18n"
import { useIntegrationOrdersQuery } from "./queries/use-integration-orders-query"
import { MutateOptions } from "@tanstack/vue-query"

// this is the main hook for the integration orders, it works with the integrationId
export const useIntegrationOrders = (integrationId: MaybeRef<IntegrationId>, status?: IntegrationOrderStatus) => {
  const { data: orders, isPending: isLoading } = useIntegrationOrdersQuery(integrationId, status)
  const { t } = useI18n()

  const { trackEvent } = useTracking()
  const { openSuccess, openError } = useNotifications()

  const dispatchIntegrationOrderMutation = useIntegrationOrderDispatchMutation(integrationId)

  const isDispatching = dispatchIntegrationOrderMutation.isPending

  const dispatchOrder = async (
    order: IntegrationOrderCreateData,
    options?: Partial<
      MutateOptions<
        IntegrationOrder,
        Error,
        {
          order: IntegrationOrderCreateData
        }
      >
    >
  ) => {
    trackEvent({
      feature: "integrations",
      action: "order_integration",
      metadata: { integration: integrationId, ...order },
    })

    return await dispatchIntegrationOrderMutation.mutateAsync(
      { order },
      {
        onSuccess: () => {
          openSuccess(t("integrations.orders.messages.dispatch.success"))
        },
        onError: () => {
          openError(t("integrations.orders.messages.dispatch.error"))
        },
        ...options,
      }
    )
  }

  return {
    isLoading,
    isDispatching,
    dispatchOrder,
    orders,
  }
}
