<template>
  <div class="flex flex-col h-full w-full border border-gray-200 rounded-lg overflow-hidden shadow-md bg-white">
    <!-- Header -->
    <div class="flex justify-between items-center p-4 bg-gray-50 border-b border-gray-200">
      <h3 class="m-0 text-xl text-gray-800">Propstack Assistant</h3>
      <div class="flex gap-2">
        <nice-tooltip content="Load Chat History">
          <button
            v-if="showHistoryButton"
            class="ml-4 p-2 text-2xl text-blue-700 hover:text-blue-400"
            @click="fetchMore"
          >
            <fa-icon name="history" />
          </button>
        </nice-tooltip>
        <nice-tooltip content="Clear Chat Context">
          <button
            v-if="clearHistoryButton"
            class="ml-4 p-2 text-2xl text-red-700 hover:text-red-400"
            @click="onClickClearContext"
          >
            <fa-icon name="trash" />
          </button>
        </nice-tooltip>
        <nice-tooltip content="Close Chat">
          <button
            v-if="showCloseButton"
            class="ml-4 p-2 text-2xl text-gray-500 hover:text-gray-700"
            @click="emit('closeChat')"
          >
            <fa-icon name="minus" />
          </button>
        </nice-tooltip>
      </div>
    </div>

    <!-- Loader: Loading messages -->
    <div
      v-if="loadingAPIMessages"
      :class="`sticky top-0 w-full flex flex-col gap-8 justify-center items-center p-4 z-10 bg-gray-50 shadow-md ${
        messages.length === 0 ? 'h-full' : ''
      }`"
    >
      <div
        :class="`rounded-full border-2 border-gray-200 border-t-blue-500 animate-spin ${
          messages.length === 0 ? 'w-16 h-16' : 'w-6 h-6'
        }`"
      ></div>
      <div v-if="messages.length === 0" class="text-gray-500 text-lg">Loading...</div>
    </div>

    <!-- Messages Container -->
    <div
      v-if="messages.length > 0"
      class="flex-1 overflow-y-auto p-4 bg-gray-50 scroll-smooth"
      ref="messagesContainer"
      @scroll="handleScroll"
    >
      <!-- Messages List -->
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="['flex items-start mb-3 gap-2', message.role === 'user' ? 'justify-end' : '']"
      >
        <!-- Assistant Icon -->
        <div v-if="message.role === 'assistant'" class="w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
          <img class="w-full h-full object-cover" :src="'/app-icon.png'" alt="Assistant" />
        </div>
        <!-- Message Content -->
        <div
          :class="[
            'px-3.5 py-2.5 rounded-2xl max-w-[90%] message-container',
            message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800',
          ]"
          :id="message.created_at"
        >
          <div v-if="message.role === 'user'">{{ message.content }}</div>
          <div v-else v-html="markdownToHtml(getDisplayContent(message))" class="markdown-content"></div>
        </div>
        <!-- User Icon -->
        <div v-if="message.role === 'user'" class="w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
          <img
            class="w-full h-full object-cover"
            :src="db.broker.avatarUrl || 'https://ui-avatars.com/api/?name=User&background=FF6B6B&color=fff'"
            alt="User"
          />
        </div>
      </div>
      <!-- Loader: Loading Assistant response -->
      <div v-if="isLoading" class="flex items-start mb-3 gap-2">
        <div class="w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
          <img class="w-full h-full object-cover" :src="'/app-icon.png'" alt="Assistant" />
        </div>
        <div class="px-3.5 py-3.5 rounded-2xl max-w-[90%] bg-gray-200">
          <div class="flex gap-1">
            <span class="loading-dot"></span>
            <span class="loading-dot"></span>
            <span class="loading-dot"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Input -->
    <div v-if="messages.length > 0" class="flex p-2.5 bg-white border-t border-gray-200">
      <input
        ref="messageInput"
        v-model="newMessage"
        @keyup.enter="sendMessage"
        placeholder="What's on your mind?"
        :disabled="isLoadingAnyMessage"
        class="flex-1 px-3.5 py-2.5 border border-gray-200 rounded-full mr-2 text-lg focus:outline-none focus:border-blue-500"
      />
      <button
        @click="sendMessage"
        :disabled="isLoadingAnyMessage || !newMessage.trim()"
        class="px-4 py-2.5 bg-blue-500 text-white rounded-full text-lg disabled:bg-gray-300 disabled:cursor-not-allowed"
      >
        <span>Send</span>
      </button>
    </div>

    <!-- Error -->
    <div v-if="apiHasError" class="w-full flex flex-col justify-center items-center">
      <div class="fixed bottom-20 mb-2 p-3 rounded-lg shadow-md border bg-red-50">
        <div class="flex items-center gap-3 text-center">
          <p class="text-red-500 text-lg">Something went wrong. Please try again later.</p>
          <button
            class="text-red-500 text-lg cursor-pointer px-2 py-1 rounded-full hover:bg-red-100"
            @click="apiHasError = false"
          >
            <fa-icon name="times" />
          </button>
        </div>
      </div>
    </div>

    <!-- Scroll to bottom button -->
    <div v-if="showScrollToBottomButton && !apiHasError" class="w-full flex justify-center">
      <div class="fixed bottom-20">
        <button @click="scrollToBottom" class="m-4 px-3 py-2.5 bg-blue-500 text-white rounded-full text-sm relative">
          <fa-icon name="arrow-down" />
          <span v-if="hasUnreadMessage" class="absolute -top-4 -right-2 px-2 py-1 bg-red-500 rounded-full">1</span>
        </button>
      </div>
    </div>

    <!-- No messages / New Chat -->
    <div v-else-if="messages.length === 0 && !isLoadingAnyMessage" class="flex-1 overflow-y-auto p-4 bg-gray-50">
      <div class="flex flex-col gap-6 items-center justify-center h-full w-full xl:w-2/3 mx-auto p-3">
        <div class="text-gray-500">
          <img :src="'/app-icon.png'" class="w-12 h-12" />
        </div>
        <h1 class="font-semibold text-gray-700">How can I help you today?</h1>

        <div class="flex flex-wrap justify-evenly gap-2 border-gray-200">
          <button
            v-for="(suggestion, index) in suggestions"
            :key="index"
            class="suggestion-chip text-sm px-4 py-2 rounded-full bg-white hover:bg-blue-50 text-gray-700 transition-colors duration-200 cursor-pointer flex items-center gap-2"
            @click="handleSuggestionClick(suggestion.text)"
          >
            <fa-icon :name="suggestion.icon" class="text-blue-500 text-lg" />
            <span class="text-gray-700 text-lg">{{ suggestion.text }}</span>
          </button>
        </div>

        <div class="flex p-2.5 w-full lg:w-1/2 max-w-4xl">
          <input
            ref="messageInput"
            v-model="newMessage"
            @keyup.enter="sendMessage"
            placeholder="What's on your mind?"
            :disabled="isLoading"
            class="flex-1 px-3.5 py-2.5 border border-gray-200 rounded-full mr-2 text-lg focus:outline-none focus:border-blue-500"
          />
          <button
            @click="sendMessage"
            :disabled="isLoading || !newMessage.trim()"
            class="px-4 py-2.5 bg-blue-500 text-white rounded-full text-lg disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            <span v-if="!isLoading">Send</span>
            <span v-else>Processing...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, nextTick, computed } from "vue"
import { debounce } from "lodash"
import useCore from "@/plugins/use-core"
import { useAssistantMessages } from "./useAssistantMessages"
import { itemsPerPage, scrollThresholds, suggestions, typewriterSpeed } from "./constants"
import { WebSocketMessage } from "./types"
import { markdownToHtml } from "./utils"
import { useAssistantSocket } from "./useAssistantSocket"

defineProps({
  showCloseButton: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(["closeChat"])

const { db } = useCore()

const {
  externalId,
  messages,
  isLoading: loadingAPIMessages,
  hasMore,
  currentPage,
  addMessageToChat,
  fetchMore,
  clearContext,
  sendMessage: submitMessage,
} = useAssistantMessages()

const { connect: connectAssistantSocket, disconnect: disconnectAssistantSocket } = useAssistantSocket()

const apiHasError = ref(false)
let apiTimeoutRef: NodeJS.Timeout | undefined
const messagesContainer = ref<HTMLElement | null>(null)
const isLoading = ref(loadingAPIMessages.value)
const newMessage = ref("")
const displayedContent = ref("")
const isTyping = ref(false)
const showScrollToBottomButton = ref(false)
const hasUnreadMessage = ref(false)
const allowScrollLoading = ref(false)
const showHistoryButton = ref(true)
const isLoadingAnyMessage = computed(() => isLoading.value || loadingAPIMessages.value)
const clearHistoryButton = computed(() => !isLoadingAnyMessage.value && messages.value.length > 0)

const typeMessage = async (message: string) => {
  isTyping.value = true
  displayedContent.value = ""

  for (let i = 0; i < message.length; i++) {
    displayedContent.value += message[i]
    await new Promise(resolve =>
      setTimeout(() => {
        resolve(undefined)
        if (getScrollPercentage() > 95) {
          scrollToBottom()
        }
      }, typewriterSpeed)
    )
  }

  isTyping.value = false
}

const getDisplayContent = message => {
  if (isTyping.value && messages.value[messages.value.length - 1] === message) {
    return displayedContent.value + (isTyping.value ? "▋" : "")
  }
  return message.content
}

const handleSuggestionClick = (suggestion: string) => {
  newMessage.value = suggestion
  sendMessage()
}

const getScrollPercentage = () => {
  if (!messagesContainer.value) return 0

  const container = messagesContainer.value
  const { scrollTop, scrollHeight, clientHeight } = container
  return (scrollTop / (scrollHeight - clientHeight)) * 100
}

const scrollToElement = (element: HTMLElement) => {
  if (!messagesContainer.value || !element) return

  element.scrollIntoView({
    behavior: "auto",
    block: "center",
  })
}

const scrollToBottom = () => {
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
  }
}

const getMoreMessages = debounce(() => {
  fetchMore()
}, 500)

const handleShowScrollToBottomButton = (scrollPercentage: number) => {
  // when scroll is not at bottom, show a floating button to scroll to bottom
  if (
    scrollPercentage < scrollThresholds.showScrollToBottomButton &&
    !isLoadingAnyMessage.value &&
    !showScrollToBottomButton.value
  ) {
    showScrollToBottomButton.value = true
  }
  // when scroll is at bottom, hide the floating button
  else if (scrollPercentage >= scrollThresholds.showScrollToBottomButton && showScrollToBottomButton.value) {
    showScrollToBottomButton.value = false
    hasUnreadMessage.value = false
  }
}

const handleLazyScrollLoading = (scrollPercentage: number) => {
  // enable scroll loading after scroll goes past threshold (to prevent multiple scroll loading)
  if (scrollPercentage > scrollThresholds.allowLoadMoreMessages && !allowScrollLoading.value) {
    allowScrollLoading.value = true
    return
  }

  // when scrolled to top, fetch more messages (if there are more) and reset allowScrollLoading (to prevent multiple scroll loading)
  if (
    scrollPercentage < scrollThresholds.loadMoreMessages &&
    !isLoadingAnyMessage.value &&
    hasMore.value &&
    allowScrollLoading.value
  ) {
    getMoreMessages()
    allowScrollLoading.value = false
  }
}

const handleScroll = () => {
  if (!messagesContainer.value) return

  const scrollPercentage = getScrollPercentage()
  handleLazyScrollLoading(scrollPercentage)
  handleShowScrollToBottomButton(scrollPercentage)
}

const resetState = () => {
  newMessage.value = ""
  displayedContent.value = ""
  isTyping.value = false
  showScrollToBottomButton.value = false
  hasUnreadMessage.value = false
  allowScrollLoading.value = false
  showHistoryButton.value = true
  apiHasError.value = false
  isLoading.value = false
}

const onClickClearContext = async () => {
  await clearContext()
    .then(resetState)
    .catch(error => {
      console.error("Failed to clear context:", error)
      apiHasError.value = true
      isLoading.value = false
    })
}

const onReceiveMessage = (message: WebSocketMessage) => {
  addMessageToChat({
    content: message.body,
    role: message.role,
    createdAt: message.created_at,
  })
  if (message.role === "assistant") {
    // type AI message with typewriter effect
    typeMessage(message.body)
    if (getScrollPercentage() < 97) {
      hasUnreadMessage.value = true
    }
    isLoading.value = false
    apiHasError.value = false
  }
}

const sendMessage = () => {
  if (newMessage.value.trim()) {
    isLoading.value = true
    apiHasError.value = false
    const messageContent = newMessage.value
    submitMessage(messageContent)
    newMessage.value = ""
    scrollToBottom()
  }
}

onMounted(() => {
  // Initialize chat socket with externalId
  watch(externalId, (newId, prevId) => {
    if (newId && newId !== prevId) {
      connectAssistantSocket(newId, onReceiveMessage)
    }
  })

  // Scroll to bottom when new message is added (initial message)
  watch(
    messages,
    () => {
      if (messages.value.length > 0) {
        if (messages.value.length < 11) {
          showHistoryButton.value = false
          nextTick(() => {
            scrollToBottom()
          })
        } else {
          nextTick(() => {
            const lastMessageIndex = messages.value.length - itemsPerPage * (currentPage.value - 1)
            const lastMessage = messages.value[lastMessageIndex]
            const lastMessageElement = document.getElementById(lastMessage.created_at)
            if (lastMessageElement) {
              scrollToElement(lastMessageElement)
            }
          })
        }
      }
    },
    { deep: true }
  )

  // Show error message if loading takes too long
  watch(isLoading, (newVal, oldVal) => {
    if (newVal && !oldVal) {
      apiTimeoutRef = setTimeout(() => {
        apiHasError.value = true
        isLoading.value = false
      }, 30000)
    } else if (!newVal && oldVal && apiTimeoutRef) {
      clearTimeout(apiTimeoutRef)
    }
  })
})

onUnmounted(() => {
  disconnectAssistantSocket()
})
</script>

<style scoped>
.message-container * {
  @apply text-lg;
}

.loading-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #999;
  border-radius: 50%;
  margin: 0 2px;
  animation: bounce 1.4s infinite ease-in-out both;
}

.loading-dot:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.suggestion-chip {
  border: 1px solid #e5e7eb;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.suggestion-chip:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.suggestion-chip:active {
  transform: translateY(0);
}

:deep(.markdown-content) {
  @apply break-words;
}

:deep(.markdown-content p) {
  @apply mb-2 last:mb-0;
}

:deep(.markdown-content ul),
:deep(.markdown-content ol) {
  @apply ml-4 mb-2;
}

:deep(.markdown-content ul) {
  @apply list-disc;
}

:deep(.markdown-content ol) {
  @apply list-decimal;
}

:deep(.markdown-content code) {
  @apply bg-gray-100 px-1 rounded text-sm font-mono;
}

:deep(.markdown-content pre) {
  @apply bg-gray-100 p-2 rounded mb-2 overflow-x-auto;
}

:deep(.markdown-content pre code) {
  @apply p-0;
  background-color: transparent;
}

:deep(.markdown-content a) {
  @apply text-blue-600 hover:underline;
}

:deep(.markdown-content blockquote) {
  @apply border-l-4 border-gray-300 pl-4 italic my-2;
}
</style>
